/*  */



.action-panel
{
   
            grid-row: 1;
            padding: 12px;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            background-color: #fff;
         /* Take up less space than the ItemComponent */
}
.SidePanels {
    grid-column: 2;
    overflow-y: auto;
    width:306%;
    height:auto;
    align-items: center;
    background-color: #fff;
    padding:10px;
}
/* Add a media query for smaller screens where you want to stack them */


.top-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px 15px;
    flex-wrap: wrap;
    /* Allow items to wrap onto the next line on small screens */
}

.logo {
    /*margin-right: 20px;*/
    width: 50px;
    height: 50px;
}

.logo img {
    width: 50px;
    /* or your preferred size */
    height: auto;
}

.top-navbar .app-title-link {
    text-decoration: none !important;
    color: black !important;
    /* Change this to the color you want for the text */
}

.app-title {
    font-size: 1.5em;
    flex-grow: 1;
    font-weight: bold;
    margin-right: 100px;
}
/* Add a media query for smaller screens where you want to stack them */
