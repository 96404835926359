.hashtag-manager-container {
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  justify-content: flex-start;
  margin-left: 0;
  padding-left: 0;
  gap: 10px;
}

.hashtag-manager-container h3 {
  margin-bottom: 15px;
}

.hashtag-form {
  display: flex;
  flex-direction: column;
  /* Stack children vertically */
  align-items: stretch;
  /* Stretch children to fill the width */
  /*gap: 10px; /* Space between children */
}

.hashtag-title {
  display: block;
  font-weight: bold;
  left: 0;
  margin: -1px;
  font-weight: 600;
  /* padding: 5px 10px;  */
  font-size: 18.72px; /* Reduce font size */
  height: 21.6px; /* Reduce height */
  line-height: 20px; /* Ensure text is vertically aligned */
  margin-top: 30px;
}
.label-headings {
  font-weight: bold;
  font-size: 1.17rem;
  margin-top: 7px;
}
.team-headings {
  font-weight: bold;
  font-size: 1.17rem;
}

.select-message {
  font-size: 1rem;
  color: #555;
  margin-top: 15px;
  text-align: center;
}

.hashtag-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 350px; /* Set a maximum height */
  max-width: 100%; /* Full width of container */
}

.no-hashtags {
  font-size: 1rem;
  color: #888;
  text-align: center;
  margin-top: 15px;
}

.hashtag-list .selected {
  /* Style for selected hashtags */
  background-color: #b3e5fc;
  /* Example style */
  font-weight: bold;
}

.hashtag-item.selected {
  background-color: #b3e5fc;
  /* Highlight color for selected item */
}

.loading,
.error {
  color: #d32f2f;
  margin: 10px 0;
}

.hashtag-item {
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 13.5px;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px;
  width: 220px;
  /* font-family: "Times New Roman"; */
  box-sizing: border-box;
  /* font-weight: 400; */
  border-color: rgb(221, 221, 221);
  margin-left: 10px;
  margin-top: 0;
  margin: 10px 0; /* Vertical spacing only */
  padding: 10px;
}

.submit-button {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 10px 15px;
  transition: background-color 0.3s;
}

/* .settings-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #007bff;
}

.settings-button:hover {
  color: #0056b3;
} */

.org-selector-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
  margin-left: 0;
  padding-left: 0;
  position: relative;
}

.org-team-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* width: 100%; */
  flex-grow: 1;
}

.org-selector,
.team-selector {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure full width for dropdowns */
  margin-left: 0;
}

.org-selector label,
.team-selector label {
  font-size: 1rem;
  margin-bottom: 5px;
}

.org-selector select,
.team-selector select {
  width: 100%; /* Full width for dropdowns */
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-left: 0;
}

.label-headings {
  margin-left: 0; /* Align labels to the left edge */
  font-weight: bold;
}

.settings-button {
  position: absolute; /* Position the button absolutely */
  top: 0; /* Align to the top */
  right: 0; /* Align to the right */
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #121212;
  padding: 5px;
  margin-top: 2px;
}

/* .settings-button {
  margin-left: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #121212;
  padding: 5px;
  margin-top: 0;
} */
/*on hover */
.settings-button:hover {
  color: #0056b3;
  background-color: transparent;
}
.org-label-input {
  margin-top: -1.5px;
}
