.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
        opacity: 5;
 background-color: rgba(90, 49, 49, 0.3); 
    /* Almost opaque white background */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1000;
}

.modal-content {
 
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    overflow-y: auto;
    align-items: center;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
    /* Optional shadow for better visibility */
    position: relative;
   margin-left: 90px auto;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    position: absolute; 
     top: 10px; 
   
    right: 30px;
    color: #000;
;
    /* Close button visible on white */
}
.modal-close-button:hover{
    color:blue;
    background-color: transparent;

}