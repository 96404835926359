.App {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2.5fr 0.75fr;
  grid-template-rows: auto;
  grid-gap: 10px;
  gap: 10px;
  background-color: #dcdcdc;
  padding: 72px 10px 10px;
  font-family: "Times New Roman", Times, serif;
  margin-top: 7px;
      max-height: fit-content;
}

.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  flex-wrap: wrap;
}

.logo {
  /*margin-right: 20px;*/
  width: 50px;
  height: 50px;
}

.logo img {
  width: 50px;
  height: auto;
}

.top-navbar .app-title-link {
  text-decoration: none !important;
  color: black !important;
}

.app-title {
  font-size: 1.5em;
  flex-grow: 1;
  font-weight: bold;
  margin-right: 100px;
}

.hashtag-section {
  grid-column: 1 / -1;
  grid-row: 1;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.hashtags-and-share {
  display: flex;
  align-items: center;
}

/* Panel styles */
.HashtagItemPanel,
.SidePanel,
.MainContent,
.ActionPanel {
  grid-row: 1;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.SidePanel {
  grid-column: 2;
  overflow-y: auto;
}

.MainContent {
  grid-column: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ActionPanel {
  grid-column: 4;
}

/* Scrollbar styles */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Light grey track */
}

::-webkit-scrollbar-thumb {
  background-color: #888;

  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar {
  width: 8px;
  /* Sleek scrollbar width */
  height: 8px;
  /* Needed for horizontal scrollbars */
}

* {
  scrollbar-width: thin;

  scrollbar-color: #888 #f1f1f1;
}

/* Button styles */
.button,
.button-fullwidth {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-size: 13px;
}

.button:hover,
.button-fullwidth:hover {
  background-color: #0056b3;
  /* Slightly darker on hover */
}

.button-fullwidth {
  width: 100%;
}

.button-linkstyle {
  background-color: initial;
  /* Remove background */
  color: #007bff;
  /* Link-like blue color */
  padding: 2px;
  /* Minimal padding */
  border: none;
  text-align: left;
  display: inline;
  /* Change to inline for minimal space usage */
  margin: 0;
  /* Remove any default margin */
  box-shadow: none;
  /* Remove any shadow if present */
  cursor: pointer;
  font-size: 0.8em;
  /* Smaller font size */
  line-height: normal;
  /* Standard line height */
}

.button-linkstyle:hover {
  color: #0056b3;
  /* Slightly darker on hover */
}

/* Input and form styles */
input,
select,
textarea,
.form input[type="text"],
.form textarea {
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13.5px;
  color: #646464;
  background-color: #dcdcdc;
  box-sizing: border-box;
  margin-bottom: 10px;
}

input:focus,
select:focus,
textarea:focus,
.form input[type="text"]:focus,
.form textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 3px #aad;
}

/* Checkbox, Radio, File Input, and Button adjustments */

/* Form and container styles */
.form,
.form-buttons,
.add-item-container,
.hashtag-form,
.add-item-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

/* Additional styles */
.selected-hashtags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* Align items vertically in the center */
  gap: 10px;
  /* Space between hashtags */
  padding: 0;
  margin-top: 5px;
  margin-bottom: 0;
  list-style-type: none;
  /* Remove default list styling */
}

.selected-hashtags p {
  background-color: #b3e5fc;
  border-radius: 5px;
  font-size: 12px;
  margin: 0;
  padding: 5px;
  white-space: nowrap;
  /* Prevent text from wrapping inside individual hashtag */
}

.react-select-custom__control {
  background-color: #dcdcdc;
  border-color: #ddd;
  font-size: 12px;
  min-height: 38px;
}

.share-button {
  color: black;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  margin-right: 0px;
  background-color: transparent;
}

.share-button:hover {
  color: rgb(71, 71, 221);
  background-color: transparent;
  /* Change color on hover if needed */
}

@media (max-width: 600px) {
  .SidePanel {
    width: 100%;
  }
}

.copy-button {
  background-color: initial;
  border: 2px solid #0000;
  cursor: pointer;
  position: relative;
  font-size: 13px;
}
.copy-button:hover{
    color: rgb(71, 71, 221);
      background-color: transparent;
}
.icons-section {
  display: grid;
  grid-template-columns: repeat(2, 31px);
  align-items: center;
}
.icons-section:hover{
  
      color: rgb(71, 71, 221);
      background-color: transparent;
      border:none;
    
}