.item-component-container {
  max-width: 600px; /* Adjust width as needed */
  margin: 0 auto; /* Center the component */
  padding: 20px;
  background-color: #f9f9f9; /* Light background for the component */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /*Subtle shadow for depth */
}
.edit-mode-textareas {
width:100%;
  height: 150px!important;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1px;


}
.item-component-container h3 {
  color: #333; /* Dark color for the title */
  margin-bottom: 15px;
}

.item-description {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff; /* White background for description */
  border: 1px solid #ddd; /* Border for the description box */
  border-radius: 5px;
}
.edit-areas{
  /* margin-left:0px;
  border-radius: 4px;
  padding-left: 5px;
  padding-right:11px;*/
  width:100%!important;
  position: relative;
    left: 0;
   
    /* Ensure no extra offset */
   margin-right:100px!important ;
   margin-left: -10px!important;
  margin-bottom: 1px!important;
 
    ;
}
.copy-buttons {
  /* border: 2px solid #00000000; */
  /* position: relative; */
  /* width: 24px;
  height: 24px; */
  /* background-color: transparent; */
  cursor: pointer;
  margin-left: 0px;
  background-color: transparent;
  color: #333;
  margin-right: 0px;
}
.copy-buttons:hover {
  color: rgb(71, 71, 221);
  background-color: transparent;
}
.share-button {
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
}
/* Styles for CommentList and CommentForm are inherited from their respective CSS files */
.item-title {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  width: 100%;
  display: block;
  margin-bottom: 15px;
  font-size: 1.17rem;
  font-weight: bold;
}
.item-description {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  /* Forces breaking of long words */
  display: block;
  margin-bottom: 15px;
  /* Remove the following styles to eliminate the box */
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.button-linkstylel {
  text-transform: capitalize;
  background-color: transparent;
  font-weight: 600;
  font-size: 1.12rem;
  margin-top: 1px;
  color: #0d0d0d;
margin-left:-13px;
}
.button-linkstylel:hover {
  text-transform: capitalize;
  background-color: transparent;
  color:blue;
  
}
.button-linkstyles{
  text-transform: lowercase;
    background-color: transparent;
    font-weight: 600;
    margin-right: 3px!important;
    color:#0d0d0d;
    margin-top:-2px;
    margin-bottom:0px;
    font-size:1.17rem;
    margin-left:-3px;

 

}
.button-linkstyles:hover {
  text-transform: lowercase;
  background-color: transparent;
  color: blue;

}
.button-linkstyler{
  text-transform: lowercase;
    background-color: transparent;
    font-weight: 600;
    margin-right: -12px !important;
    color: #0d0d0d;
      margin-top: -2px;
      margin-bottom: 0px;
      font-size: 1.17rem;
}
.button-linkstyler:hover {
  text-transform: lowercase;
  background-color: transparent;
  color: blue;

}
.task-id{
  margin-top: -18px!important ;
  
}
/* Set a fixed height and prevent expansion */
.quill-editor {
  width: 100%;
  max-width: 100%;
  /* margin-left: 8px; */
}

.quill-editor .ql-container {
  height: 140px !important;
  max-height: 140px;
  overflow-y: auto !important;
  overflow-x: hidden;
  border: 1px solid #ccc;
  background-color: white;
  font-family: inherit;
  color: black;
  margin-left: 10px;
  margin-right:-10px;
  box-sizing: border-box;
  
}

.quill-editor .ql-editor {
  height: 100% !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
  /* Allow scrolling horizontally for long content */
  word-break: break-word;
  /* Break long words */
  white-space: pre-wrap;
  /* Preserve line breaks */
  font-size: 14px;
  padding: 8px;
  margin-left: 10px;
  
}

 .ql-toolbar {
  background-color: white;
  /* Or any color you want */
  border: 1px solid #ccc;
    margin-left: 10px;
  
  /* Blend it with the editor below */
  
  margin-right: -10px;
} 