.comment-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.comment-content ul,
.comment-content ol {
  padding-left: 20px;
}

.comment-content ul {
  list-style-type: disc;
}

.comment-content ol {
  list-style-type: decimal;
}

.comment-content li {
  margin-bottom: 5px;
}


.comments-container {
  max-height: 350px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.comment {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.comment-content {
  width: 100%;
}

.log-comment {
  font-size: 12px;
  color: grey;
}