.priority-box {
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  box-sizing: border-box;
  min-height: 40px;
  width: 100%;
  margin: 2px;
  font-family: Arial;
  font-size: 13.5px;
}
.date-inputs{
  box-sizing: border-box;
    min-height: 40px;
    width: 111%;
    margin: 2px;
    font-family: Arial;
    font-size: 13.5px;
}
.Action {
  font-weight: bold;
  font-size: 1.17rem;
  margin: 20px;
  margin-left: 2px;
}

.ActionPanels {
  width: 200px;
}

/* Tooltip container */
.react-select-custom__option {
  position: relative;
}

.react-select-custom__option div[title]:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #555;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
  top: 100%;
  /* Position the tooltip below the text */
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.react-select-custom__option div[title]:hover::after {
  visibility: visible;
  opacity: 1;
}

.react-select-custom__option div[title]::after {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}
