.settings-panel {
  background-color: #f8f9fa;
  padding: 0px;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.form-section {
  margin-bottom: 20px;
}

h4,
h2 {
  font-size: 1.25rem;
  margin-bottom: 15px;
  color: #333;
  text-align: left;
  padding: 5px;
  /* Center-align all headers */
}

input,
select,
textarea {
  width: 30%;
  /* Adjusted width for smaller inputs */
  padding: 6px;
  /* Reduced padding */
  margin-top: 6px;
  /* Reduced margin */
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  /* Reduced margin */
  border: 1px solid #ced4da;
  /* Light grey border */
  border-radius: 4px;
  font-size: 0.9rem;
  /* Reduced font size */
}

input::placeholder,
textarea::placeholder {
  color: #6c757d;
}

button {
  background-color: #007bff;
  /* Standard blue color */
  color: white;
  padding: 8px 16px;
  /* Reduced padding */
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  /* Slightly smaller font size */
  text-transform: uppercase;
  transition: background-color 0.3s;
}

/* button:hover {
  background-color: #0056b3;
 
} */

.error-message {
  color: #ff0000;
  /* Red for errors */
  margin-right: 10px;
  margin-bottom: 10px;
}

.success-message {
  color: #28a745;
  /* Green for success */
  margin-bottom: 10px;
}

.multi-select-instruction {
  font-size: 0.9rem;
  color: #6c757d;
}

.select-multiple {
  height: 100px;
}

.custom-button {
  background-color: #0d6efd;
  color: white;
  border: none;
  padding: 8px 16px;
  /* Reduced padding */
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

/* .custom-button:hover {
  background-color: #0a58ca;
  
} */

.custom-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}
