.gantt-container {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: #f8f8f8;
  overflow: auto;
}

.filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 15px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filters label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
  min-height: 65px;
}

/* Common styling for select dropdowns and date inputs */
.filters select,
.filters input[type="date"] {
  padding: 8px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #ccc;
  transition: all 0.2s;
  width: 100%;
  box-sizing: border-box;
}

/* Unique colors for each date input */
.creation-date input[type="date"] {
  background-color: #e8f0fe;
  border-color: #4285f4;
}

.start-date input[type="date"] {
  background-color: #e0ffe0;
  border-color: #34a853;
}

.end-date input[type="date"] {
  background-color: #fff3e0;
  border-color: #fbbc05;
}

.fix-date input[type="date"] {
  background-color: #ffe0e0;
  border-color: #ea4335;
}

/* Focus effect for input fields */
.filters select:focus,
.filters input[type="date"]:focus {
  outline: none;
  border-color: #4285f4;
  box-shadow: 0 0 5px rgba(66, 133, 244, 0.2);
}

.gantt-chart-wrapper {
  height: 70vh;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tasks-without-dates {
  margin-top: 30px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
  font-weight: 600;
  position: sticky;
  top: 0;
}

tr:hover {
  background-color: #f5f5f5;
}

td a {
  color: #007bff;
  text-decoration: none;
}
