.mobile-layout {
position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Align content to the top */
    height: 100vh;
    width: 100vw;
    /* Ensures it takes full viewport width */
    padding: 0;
    /* Remove padding to allow full width usage */
    margin: 0;
    /* Remove margin to fit the form properly */
    background-color: #ffffff;
    /* Set a background color */
    box-sizing: border-box;}

/* .top-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
} */

.navigation-bar {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
        right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 10;
}

.section-display {
    margin-top: 60px;
    /* Adjust based on your navbar height */
/* width: 100%; */
    flex: 1;
    padding: 1px;
    /* Padding around sections */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* Stretches children to fill width */
    overflow-y: auto;
    /* Allows scrolling */
    background-color: #FFFFFF;
    /* White background for content area */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Adds subtle shadow */
    border-radius: 8px;

}
@media (max-width: 768px) {
    .section-display {
        padding: 15px;
        /* Slightly smaller padding on smaller screens */
    }
}
button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

button:focus {
    outline: none;
}